import React, { useEffect } from 'react';
import Image from 'next/legacy/image';
import { getLanguage, t } from 'react-switch-lang';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import MainLayout from '../../components/MainLayout';
import { checkSession } from '../../components/Router';
import cardImage from '../../public/assets/images/card/CardFront.png';
import heroBack from '../../public/assets/images/MapleLeaves.png';
import stock1 from '../../public/assets/images/landing/Stock1.jpg';
import stock2 from '../../public/assets/images/landing/Stock2.jpg';
import stock3 from '../../public/assets/images/landing/Stock3.jpg';
import stock4 from '../../public/assets/images/landing/Stock4.jpg';
import laptopPhone from '../../public/assets/images/landing/LaptopPhone.png';
import laptopPhoneFr from '../../public/assets/images/landing/LaptopPhoneFr.png';
import tapToPay from '../../public/assets/images/landing/TapToPayOrg.svg';
import styles from '../../styles/Landing.module.scss';
import Link from '../../components/Link';
import StoreButtons from '../../components/StoreButtons';
import { events, logAmpEvent } from '../../utils/Amplitude';
import ReplaceTextWithElement from '../../components/ReplaceTextWithElement';
import { mapHash } from '../../utils/FrenchUrlMapping';

function Hero() {
  return (
    <section className={styles.hero}>
      <div className={`${styles.container} cont`}>
        <div className={styles.row}>
          <div className={styles.backgroundContTop}>
            <div className={styles.backgroundCont}>
              <Image
                src={heroBack}
                className={styles.background}
                width={700}
                height={520}
                alt=""
                aria-hidden="true"
                unoptimized
              />
            </div>
          </div>
          <div className={styles.textCont}>
            <div className={`${styles.textCenter} ${styles.landingHeader}`}>
              <h1 className={styles.title}>
                {t('OfficialCardName')}
              </h1>
              <p className={styles.subtitle}>
                {t('Landing_Lbl_Main_Subheader')}
              </p>
              {/* <StoreButtons id="hero-download-btn" /> */}
              <Link
                href="/purchase"
                text={t('Landing_Btn_Purchase_Now')}
                ampEvent={events.USER_CLICKED_CTA_HERO_LANDING}
                className={`mapleBtn dark solid ${styles.button}`}
                aria-label={t('LinkDescription_Landing_PurchaseNow')}
              />
              <Link
                href="/register"
                text={t('Landing_Lbl_Already_Have_Card')}
                ampEvent={events.USER_CLICKED_REGISTER_LANDING}
                className={styles.registerLink}
                aria-label={t('LinkDescription_Landing_Create_Account')}
              />
            </div>
          </div>

          <div className={styles.imgCont}>
            <Image
              className="img-responsive"
              src={cardImage}
              // aria-hidden="true"
              alt=""
              aria-hidden="true"
              unoptimized
            />
          </div>
        </div>
      </div>
    </section>
  );
}

function StockImage({ src }) {
  return (
    <div className="col">
      <Image
        className="img-responsive"
        src={src}
        layout="intrinsic"
        alt=""
        aria-hidden="true"
        unoptimized
      />
    </div>
  );
}

function StockImages() {
  return (
    <section className={styles.stockImages}>
      <div className={`row ${styles.stockRow}`}>
        <StockImage src={stock1} />
        <StockImage src={stock2} />
        <StockImage src={stock3} />
        <StockImage src={stock4} />
      </div>
    </section>
  );
}

function Benefit({ icon, iconImg, iconId, title, subtitle, subtitle2 = '' }) {
  return (
    <div className={`col-md-4 ${styles.cont}`}>
      <div className="d-flex">
        {icon ? (
          <FontAwesomeIcon
            icon={icon}
            size="3x"
            fixedWidth
            className={styles.icon}
            id={iconId}
          />
        ) : (
          <div id={iconId} className={styles.icon}>
            <Image src={iconImg} unoptimized />
          </div>
        )}
        <div>
          <h3 className={`h5 ${styles.title}`}>{title}</h3>
          <p className={styles.subtitle}>
            <ReplaceTextWithElement
              text={subtitle}
              replaceWith={
                <i>Interac</i>
              }
              replaceKey="Interac"
            />
          </p>
          <p className={styles.subtitle2}>{subtitle2}</p>
        </div>
      </div>
    </div>
  );
}

function CardBenefits() {
  const lang = getLanguage();
  return (
    <section id={mapHash('CardBenefits', lang)} className={styles.benefits}>
      <div className="d-flex justify-content-center text-center">
        <h2 className={`h1 ${styles.header}`}>
          {t('Landing_Title_Benefits_Header')}
        </h2>
      </div>
      <div className="row mx-auto justify-content-md-center">
        <Benefit
          icon={['fal', 'globe-americas']}
          title={t('Landing_Lbl_Globally_Header')}
          subtitle={t('Landing_Lbl_Globally_Subheader')}
        />
        <Benefit
          icon={['fal', 'user-shield']}
          title={t('Landing_Lbl_Liability_Header')}
          subtitle={t('Landing_Lbl_Liability_Subheader')}
          subtitle2={t('Landing_Lbl_Liability_Subheader2')}
        />
        <Benefit
          icon={['fal', 'piggy-bank']}
          title={t('Landing_Lbl_Budgeting_Header')}
          subtitle={t('Landing_Lbl_Budgeting_Subheader')}
        />
        <Benefit
          icon={['fal', 'search-dollar']}
          title={t('Landing_Lbl_Credit_Header')}
          subtitle={t('Landing_Lbl_Credit_Subheader')}
        />
        <Benefit
          icon={['fal', 'donate']}
          title={t('Landing_Lbl_Reload_Header')}
          subtitle={t('Landing_Lbl_Reload_Subheader')}
        />
        <Benefit
          iconImg={tapToPay}
          iconId={styles.TapToPay}
          title={t('Landing_Lbl_TapToPay_Header')}
          subtitle={t('Landing_Lbl_TapToPay_Subheader')}
        />
      </div>
    </section>
  );
}

function AppFeatureBullet({ text }) {
  return (
    <div className={`d-flex ${styles.checkCont}`}>
      <FontAwesomeIcon
        icon={['fas', 'check-circle']}
        className={`my-auto ${styles.icon}`}
      />
      <p className="my-auto">
        <ReplaceTextWithElement
          text={text}
          replaceWith={
            <i>Interac</i>
          }
          replaceKey="Interac"
        />
      </p>
    </div>
  );
}

function MonitorCard() {
  const lang = getLanguage();
  return (
    <section id={mapHash('CardholderApps', lang)} className={styles.monitor}>
      <div className={styles.container}>
        <div className={`row mx-auto ${styles.row}`}>
          <div className={`col ${styles.imgCont}`}>
            <Image
              className="img-responsive"
              src={lang === 'fr' ? laptopPhoneFr : laptopPhone}
              alt=""
              aria-hidden="true"
              unoptimized
            />
          </div>
          <div className="col">
            <div className={styles.textCenter}>
              <div>
                <h2 className={`h1 ${styles.title}`}>
                  {t('Landing_Title_Monitor_Card_Header')}
                </h2>
                <p className={styles.subtitle}>
                  {t('Landing_Lbl_Monitor_Card_Subheader')}
                </p>

                <AppFeatureBullet
                  text={t('Landing_Lbl_Monitor_Card_Feature1')}
                />
                <AppFeatureBullet
                  text={t('Landing_Lbl_Monitor_Card_Feature2')}
                />
                <AppFeatureBullet
                  text={t('Landing_Lbl_Monitor_Card_Feature3')}
                />
                <AppFeatureBullet
                  text={t('Landing_Lbl_Monitor_Card_Feature4')}
                />

                <div className={styles.checkCont}>
                  <p className={styles.linkP}>
                    {t('Landing_Lbl_More_Feature')}
                  </p>
                </div>
                <p className={`my-auto ${styles.mobileHeader}`}>
                  {t('Landing_Lbl_Download')}
                </p>
                <StoreButtons
                  classNameApple={styles.appleApp}
                  classNameGoogle={styles.googleApp}
                  section="Landing"
                />

                <span>
                  {`${t('Landing_Lbl_Login_Link_Or_Upper')} `}
                  <Link
                    href="/register"
                    aria-label={t('LinkDescription_Landing_Create_Account')}
                    ampEvent={events.USER_CLICKED_REGISTER_LANDING}
                  >
                    {`${t('Create_Account_Btn')}`}
                  </Link>
                  {` ${t('Landing_Lbl_Login_Link_Or_Lower')} `}
                  <Link
                    href="/login"
                    aria-label={t('LinkDescription_Landing_Login')}
                    ampEvent={events.USER_CLICKED_LOGIN_LANDING}
                  >
                    {`${t('Login_Title')}`}
                  </Link>
                  {` ${t('Landing_Lbl_Login_Link')}`}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function PurchaseStep({ number, title, subtitle, bold = '' }) {
  return (
    <div className={`col-md-4 ${styles.cont}`}>
      <div className="d-flex">
        <div>
          <div className={styles.icon}>
            <p className={styles.iconText}>{number}</p>
          </div>
        </div>
        <div className={styles.text}>
          <h3 className={`h5 ${styles.title}`}>{title}</h3>
          <span>
            <p className={styles.subtitle}>
              <ReplaceTextWithElement
                text={subtitle}
                replaceWith={
                  <i>Interac</i>
                }
                replaceKey="Interac"
              />
              <span className={styles.subtitleBold}>{bold}</span>
            </p>
          </span>
        </div>
      </div>
    </div>
  );
}

function PurchaseInfo() {
  return (
    <section className={styles.purchase}>
      <div className={styles.cont}>
        <div className="justify-content-center text-center">
          <h2 className={`h1 ${styles.header}`}>
            {t('Landing_Title_Purchase_Header')}
          </h2>
          <p className={styles.subheader}>
            {t('Landing_Lbl_Purchase_Subheader')}
          </p>
        </div>
        <div className="row mx-auto justify-content-md-center">
          <PurchaseStep
            number="1"
            title={t('Landing_Lbl_Step1_Header')}
            subtitle={t('Landing_Lbl_Step1_Subheader')}
            bold={` ${t('Landing_Lbl_Step1_Subheader_Bold')}`}
          />
          <PurchaseStep
            number="2"
            title={t('Landing_Lbl_Step2_Header')}
            subtitle={t('Landing_Lbl_Step2_Subheader')}
          />
          <PurchaseStep
            number="3"
            title={t('Landing_Lbl_Step3_Header')}
            subtitle={t('Landing_Lbl_Step3_Subheader')}
          />
        </div>
        <div className="justify-content-center text-center">
          <Link
            href="/purchase"
            text={t('Landing_Btn_Get_Started')}
            ampEvent={events.USER_CLICKED_CTA_BOTTOM_LANDING}
            className={`mapleBtn dark solid justify-content-center text-center ${styles.button}`}
            aria-label={t('LinkDescription_Landing_GetStarted')}
          />
        </div>
      </div>
    </section>
  );
}

function Landing() {
  useEffect(() => logAmpEvent(events.USER_VIEWED_MAIN_LANDING_PAGE), []);

  return (
    <MainLayout title={t('OfficialCardName')} description={t('PageDescription_Main')} isLandingPage>
      <Hero />
      <StockImages />
      <CardBenefits />
      <MonitorCard />
      <PurchaseInfo />
    </MainLayout>
  );
}

export default checkSession(Landing);
export { getStaticPaths, getStaticProps } from '../../utils/DynamicRoutes';
