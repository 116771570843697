import { getLanguage, translate, t } from 'react-switch-lang';
import Image from 'next/legacy/image';

import { events } from '../utils/Amplitude';
import google from '../public/assets/images/logos/Google.png';
import googleFr from '../public/assets/images/logos/GoogleFr.png';
import apple from '../public/assets/images/logos/Apple.png';
import appleFr from '../public/assets/images/logos/AppleFr.png';
import { ExternalLink } from './Link';
import { useRouter } from './Router';

function StoreButtons({ classNameApple, classNameGoogle, section }) {
  const router = useRouter();
  return (
    <div className="row">
      <div className={`col-4 ${classNameApple}`}>
        <ExternalLink
          href={t('Link_MapleiOSApp')}
          aria-label={t('ImageDescription_Maple_AppleAppStore')}
          ampEvent={events.USER_CLICKED_DOWNLOAD_APP}
          ampEventProps={{
            Page: router.pathname.replace('[lang]', '').replace('//', '/'),
            Section: section,
            store: 'Apple Store',
          }}
        >
          <Image
            src={getLanguage() === 'fr' ? appleFr : apple}
            alt={t('ImageDescription_Maple_AppleAppStore')}
            objectFit="contain"
            unoptimized
          />
        </ExternalLink>
      </div>
      <div className={`col-4 ${classNameGoogle}`}>
        <ExternalLink
          href={t('Link_MapleAndroidApp')}
          aria-label={t('ImageDescription_Maple_GooglePlayStore')}
          ampEvent={events.USER_CLICKED_DOWNLOAD_APP}
          ampEventProps={{
            Page: router.pathname.replace('[lang]', '').replace('//', '/'),
            Section: section,
            store: 'Google Play',
          }}
        >
          <Image
            src={getLanguage() === 'fr' ? googleFr : google}
            alt={t('ImageDescription_Maple_GooglePlayStore')}
            objectFit="contain"
            unoptimized
          />
        </ExternalLink>
      </div>
    </div>
  );
}

export default translate(StoreButtons);
